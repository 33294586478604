<template>
    <div>
        <Header backType="blank" :backStyle="style" :svgColor="supermarket ? '' : '#fff'" :back="true">
            <span @click="openDdianPing" class="goH5" v-if="showOpenDianPing()" slot="rightBtn">美团详情</span>
        </Header>
        <div class="takeOutEleDetail">
            <div
                class="merchantImg"
                v-if="!supermarket"
                :style="{backgroundImage:'url(' + (takeOutDetail.shopImg || 'https://pic.downk.cc/item/5e81a6f2504f4bcb0418357b.jpg') + ')',backgroundSize: 'cover'}"
            ></div>
            <div v-else style="margin-top: 60px"></div>
            <div class="merchantContent">
                <div class="merchantName">{{takeOutDetail.shopName}}</div>
                <div class="merchantGroup">
                    <div class="merchantGroupHead">订单信息</div>
                    <ul class="merchantGroupList">
                        <li>
                            <span class="merchantGroupLabel">订餐时间</span>
                            <span class="merchantSuccess merchantGroupTxt">{{takeOutDetail.orderTime}}</span>
                        </li>
                        <li>
                            <span class="merchantGroupLabel">送餐时间</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.deliveryTime}}</span>
                        </li>
                        <li>
                            <span class="merchantGroupLabel">收餐人</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.bookingName}}</span>
                        </li>
                        <li>
                            <span class="merchantGroupLabel">手机号码</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.bookingPhoneNum}}</span>
                        </li>
                        <li>
                            <span class="merchantGroupLabel">送餐地址</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.bookingAddress}}</span>
                        </li>
                        <li v-if="takeOutDetail.actualQty">
                            <span class="merchantGroupLabel">人数</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.actualQty}}</span>
                        </li>
                        <li>
                            <span class="merchantGroupLabel">预算金额</span>
                            <span class="merchantGroupTxt">{{orderDetail.amount | price}}</span>
                        </li>
                        <li v-if="takeOutDetail.itemName=='美餐外卖'">
                            <span class="merchantGroupLabel">发票信息</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.invoiceMsg}}</span>
                        </li>
                        <li v-if="takeOutDetail.itemName=='美餐外卖'">
                            <span class="merchantGroupLabel">备注</span>
                            <span class="merchantGroupTxt">{{takeOutDetail.remark}}</span>
                        </li>
                    </ul>
                </div>
                <div class="merchantGroup">
                    <div class="merchantGroupHead">订单状态</div>
                    <ul class="merchantGroupList">
                        <li>
                            <span class="merchantGroupLabel">订餐状态</span>
                            <span class="status">{{takeOutDetail.statusTxt}}</span>
                        </li>
                        <li>
                            <span class="merchantGroupLabel">收货时间</span>
                            {{takeOutDetail.pickupTime}}
                        </li>
                    </ul>
                </div>

                <div class="merchantGroup">
                    <div class="merchantGroupHead">实际用餐明细</div>
                    <ul class="merchantMoList">
                        <li v-for="(item,index) in sectionList" :key="index">
                            {{item.name}}
                            <span class="merchantMoPrice">{{item.price | price}}</span>
                            <span class="merchantMoNum">{{item.count | count}}</span>
                        </li>
                        <li class="merchantMoAllPrice" v-if="total.deliveryPrice">
                            配送费
                            <span class="merchantMoAllPriceNum">{{total.deliveryPrice | price}}</span>
                        </li>
                        <li class="merchantMoAllPrice" v-if="total.packingPrice">
                            包装费
                            <span class="merchantMoAllPriceNum">{{total.packingPrice | price}}</span>
                        </li>
                        <li class="merchantMoAllPrice" v-if="total.price">
                            总金额
                            <span class="merchantMoAllPriceNum">{{total.price | price}}</span>
                        </li>
                    </ul>
                </div>
                <div class="merchantBtnRow">
                    <div class="merchantGroupHead">水单</div>
                    <viewer v-if="waterFilesList.length" :images="waterFilesList" class="imgContainer">
                        <template v-for="(list) in waterFilesList">
                            <div :key="list.url">
                                <img v-if="list.fileType != '.pdf'" :src="list.url" alt/>
                                <div  @click="openPdf(list.url)" v-else>
                                    <i-icon style="width:.8rem;height:.8rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                                </div>
                            </div>
                        </template>
                    </viewer>
                    <div class="nodata" v-else>暂无数据！</div>
                </div>
                <div class="merchantBtnRow">
                    <div class="merchantGroupHead">发票</div>
                    <viewer
                        v-if="invoiceFilesList.length"
                        :images="invoiceFilesList"
                        class="imgContainer"
                    >
                        <template v-for="(list) in invoiceFilesList">
                            <div :key="list.url">
                                <div  @click="openPdf(list.url)" v-if="list.fileType == '.pdf'">
                                    <i-icon style="width:.8rem;height:.8rem" class="icon-pdf" color="var(--themeColor)" name="icon-pdf" />
                                </div>
                                <div  @click="openOfd(list.url)" v-else-if="list.fileType == '.ofd'">
                                    <img src="../../assets/img/ofd.png" style="width:.8rem;height:.8rem"/>                             </div>
                                <img v-else :src="list.url" alt/>
                            </div>
                        </template>
                    </viewer>
                    <div class="nodata" v-else>暂无数据！</div>
                </div>
                <div class="merchantBtnRow" v-if="takeOutDetail.invoiceReason">
                    <div class="merchantGroupHead">原因</div>
                    <p class="invoiceReason">
                        {{takeOutDetail.invoiceReason}}
                    </p>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
import mealDdianPing from "@/components/Meal/mealDdianPing.js";
import { UploadPlug } from "smart-filer";
import { SmartStorage } from "smart-core-util";
import { Toast } from 'mint-ui';
const uploadInstance = new UploadPlug({
    host: process.env.VUE_APP_GATEWAY
});
export default {
    name: "orderDetail",
    mixins: [mealDdianPing],
    data() {
        return {
            takeOutDetail: {},
            sectionList: [],
            total: {},
            waterFilesList: [],
            invoiceFilesList: [],
            style: {
                width: "0.3rem",
                height: "0.3rem",
                borderRadius: "50%",
                background: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            },
            supermarket: false
        };
    },
    computed: {
        ...mapGetters(["orderDetail"])
    },
    mounted() {
        this.getTakeoutDetail();
        this.getWaterFiles();
        this.getInvoiceFiles();
        this.supermarket = SmartStorage.get("supermarket")
    },
    filters: {
        price: value => {
            if (value || value === 0) {
                return "¥" + value;
            }
        },
        count: value => {
            if (value) {
                return "X" + value;
            }
        }
    },
    methods: {
        openPdf(pdfUrl){
            this.iJsBridge.openPdfImg(pdfUrl)
            // window.open(pdfUrl)
        },
        openOfd(){
            Toast('暂不支持预览Ofd格式文件');
        },
        showOpenDianPing() {
            let orderDetail = this.orderDetail;
            if (
                orderDetail &&
                orderDetail.itemType == 7 &&
                orderDetail.status > 0 &&
                orderDetail.itemName !== "美餐外卖"
            ) {
                return true;
            }
            return false;
        },
        openDdianPing() {
            const params = {
                itemId: this.orderDetail.itemId
            };
            let _this = this;
            this.openTakeoutDianPing(params, () => {
                miceService.refreshDianPingOrder(params).then(res => {
                    if (res && res.success) {
                        _this.getTakeoutDetail();
                    }
                });
            });
        },
        getTakeoutDetail() {
            let params = {
                itemId: this.orderDetail.itemId
            };
            miceService.getTakeoutDetail(params).then(res => {
                if (res && res.success) {
                    this.takeOutDetail = res.content;
                    this.total = res.content.total || {};
                    const sectionList = (res.content.orderContent || {}).foods;
                    this.sectionList = sectionList;
                }
            });
        },
        getWaterFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        relatedId: this.orderDetail.itemId,
                        relatedType: [
                            "settle",
                            "settle_reback",
                            "SupplierSettle"
                        ],
                        catalog: "水单"
                    }
                },
                callback: data => {
                    this.waterFilesList = data.content;
                }
            });
        },
        getInvoiceFiles() {
            uploadInstance.queryFile({
                filterData: {
                    filterData: {
                        processId: this.orderDetail.proposalId,
                        itemId: this.orderDetail.itemId,
                        relatedType: ["Invoice", "Invoice_reback"],
                        catalog: "发票"
                    }
                },
                callback: data => {
                    this.invoiceFilesList = data.content;
                }
            });
        }
    }
};
</script>

<style lang="scss">
.merchantBtnRow .icon-pdf{
    width: 0.8rem;
    height: 0.8rem;
    margin: 0.2rem 0.2rem 0 0;
    border-radius: 0.2rem;
}
.invoiceReason{
    word-wrap:break-word;
    text-align: left;
    margin-top: .2rem
}
.goH5{
    background:white
}
@import "../MealOrderDetail/MealOrderDetail";
</style>